body {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px
}

h3 {
  color: var(--Color-black, #232933);
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  /* 125% */
}

.truncate-two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines you want */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  /* Override the nowrap if previously set */
}

.navbarContainer {
  display: flex;
  padding: 0px 120px 120px 120px;
  flex-direction: column;
  align-self: stretch;
  border-radius: 0px 0px 60px 60px;
  background:
    linear-gradient(180deg, rgba(255, 255, 255, 0.00) 14.92%, rgba(255, 184, 0, 0.16) 100%);
  padding-bottom: 120px;
  border: 0px solid #000;
  margin-bottom: 100px;
}

.hero2Container {
  display: flex;
  padding: 120px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 60px;
  background: #007C9C;
  margin-bottom: 120px;
}

.hero2Container h3 {
  color: var(--Color-white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 30px;
}

.hero2Container p {
  color: var(--Color-white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 177.778% */
}

.heroHeader {
  color: var(--Color-black, #232933);
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  /* 116.667% */
}

.users {
  display: flex;
  width: 200px;
  padding: 20px 18px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.userProof {
  display: flex;
  width: 200px;
  padding: 20px 18px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 30px;
}


.carousel-container {
  position: relative;
  overflow: hidden;
  /* Keeps everything contained within the boundaries of the carousel */
}

.carousel-container::before,
.carousel-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25%;
  /* Controls the width of the shadow effect */
  pointer-events: none;
  /* Ensures shadow doesn't interfere with slide interactions */
  background: linear-gradient(to right, rgba(247, 248, 250, 0.6), transparent);
  z-index: 10;
}

.carousel-container::before {
  left: 0;
}

.carousel-container::after {
  right: 0;
  background: linear-gradient(to left, rgba(247, 248, 250, 0.6), transparent);
}


.userProof img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: url("https://felix-dziekan.de/typo3conf/ext/typo3_blogs/Resources/Public/Images/Profile.jpg") lightgray 50% / cover no-repeat;
}

.userProof h5 {
  margin-top: 20px;
  color: #303742;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.userProof span {
  color: var(--Color-body, #536177);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.featureBox {
  display: flex;
  max-width: 420px;
  width: 360px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #E5E9F0;
  background: #FFF;
}

.featureBox h3 {
  border: none;
  color: var(--Color-black, #232933);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.featureBox p {
  color: var(--Color-body, #536177);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 880px;
  /* 177.778% */
}

.hero2Container button {
  display: flex;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  background: rgba(255, 255, 255, 0.20);
}

.hero2Container button img {
  width: 24px;
  height: 24px;
}

#featureVideos .dropdown {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.videoBackground {
  width: 630px;
  height: 600px;
  border-radius: 30px;
  background: linear-gradient(180deg, #5FD0F3 0%, #FFF 100%);
  padding: 40px;
}

.footerBackground {
  border-radius: 40px;
  background: #000;
  display: flex;
  padding-top: 60px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.footerBackground h3 {
  color: var(--Shades-0, #FFF);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 25.2px */
}

.footerBackground h3 {
  text-align: left;
  margin-bottom: 1.5rem;
}




#featureVideos {
  margin-bottom: 120px;
}

#featureVideos .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
  background: #FFF;
  padding: 20px
}

#featureVideos .cardOpen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Color-skyblue, #5FD0F3);
  background: #E4FEFF;
}

#featureVideos .card-header {
  border: none;
  color: var(--04, #111);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  flex: 1 0 0;
  width: 100%;
  padding: 0;
  /* 120.833% */
}

#featureVideos .cardOpen .card-header {
  background: #E4FEFF;
}

#featureVideos .card-body {
  color: var(--Color-black, #232933);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  align-self: stretch;
  padding-top: 0;
  padding: 0;
  /* 177.778% */
}

.blogPreview {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: linear-gradient(191deg, rgba(214, 214, 214, 0.30) 8.06%, rgba(242, 242, 242, 0.30) 93.65%);
}

.blogPreview img {
  width: 100%;
  max-height: 299px;
}

.blogPreview h2 {
  color: var(--Color-black, #232933);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.actionButton {
  display: flex;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--Color-yellow, #F34E4E);
  color: var(--Color-white, #FFF);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 0;
}

.blogCard {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: linear-gradient(191deg, rgba(214, 214, 214, 0.30) 8.06%, rgba(242, 242, 242, 0.30) 93.65%);
}

.blogCardTitle {
  color: var(--Color-black, #232933) !important;
  font-family: Montserrat !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

@media (max-width: 768px) {
  .navbarContainer {
    padding: 0;
    margin-bottom: 50px;
  }

  .hero2Container{padding-left: 0; padding-right: 0;}

  .hiddenMobile{
    display: none;
  }
}