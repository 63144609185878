.teleprompter-container {
    width: 100%;
    height: 700px; /* Adjust this height as needed */
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: black;
    color: white;
    font-size: 24px;
    padding: 10px;
    position: relative;
  }
  
  .teleprompter-text {
    height: 100%;
    overflow-y: scroll;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Keeps the text aligned at the top */
    align-items: flex-start;
  }
  
  .teleprompter-text p:first-child {
    height: 600px; /* Make the first <p> element 200px high */
    line-height: 600px; /* Vertically center the text inside the <p> */
    margin: 0; /* Ensure no extra margin is added */
  }
  
  .teleprompter-controls {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  
  .teleprompter-controls button {
    background-color: #ff6347; /* Default red color for other buttons */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    border-radius: 5px;
  }
  
  .teleprompter-controls button:hover {
    background-color: #ff4500; /* Darker red on hover for other buttons */
  }
  
  .teleprompter-controls .start-btn {
    background-color: #28a745; /* Success green color */
  }
  
  .teleprompter-controls .start-btn:hover {
    background-color: #218838; /* Darker green on hover */
  }